import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { getCookie } from './cookies';

const COOKIE_EXPIRATION_DAYS = 30;
const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const COOKIE_EXPIRATION_MS = COOKIE_EXPIRATION_DAYS * ONE_DAY_IN_MILLISECONDS;
const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_timestamp',
];

const getUtmParams = url => {
  const urlParams = new URLSearchParams(url);
  const utmParams = {};
  const currentTimeStamp = Date.now();

  UTM_PARAMS.forEach(param => {
    if (urlParams.has(param)) {
      utmParams[param] = urlParams.get(param);
    }
  });

  if (Object.keys(utmParams).length > 0) {
    utmParams['utm_timestamp'] = currentTimeStamp;
  }

  return utmParams;
};

const setCustomCookie = (name, value, expiryDays, sameSite = 'Strict') => {
  if (typeof window !== 'undefined') {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + (expiryDays || 365));

    const cookie = [
      `${name}=${value}`,
      `expires=${exdate.toUTCString()}`,
      `domain=${window.location.hostname.replace(
        /^([a-z]+?\.)(?=[a-z]+?\.)/,
        ''
      )}`,
      `path=/`,
    ];

    if (window.location.protocol === 'https:') {
      cookie.push('Secure');
      cookie.push(`SameSite=${sameSite}`);
    }

    document.cookie = cookie.join(';');
  }
};

export const setTrackingCookies = () => {
  if (typeof window !== 'undefined') {
    const referrer = document.referrer;
    const currentUrlParams = new URLSearchParams(window.location.search);
    const currentUtmParams = getUtmParams(currentUrlParams.toString());
    const currentHostname = window.location.hostname;

    if (referrer && !referrer.includes(currentHostname)) {
      try {
        const referrerUrl = new URL(referrer);
        const referrerOrigin = `${referrerUrl?.origin}${referrerUrl?.pathname}`;

        setCustomCookie('origin', referrerOrigin, {
          expires: COOKIE_EXPIRATION_DAYS,
        });
      } catch (e) {
        Sentry.captureException(new Error(`Invalid referrer URL`), {
          extra: {
            error: e,
          },
        });
      }
    } else if (!referrer && !currentUrlParams.toString()) {
      setCustomCookie('origin', 'direct', {
        expires: COOKIE_EXPIRATION_DAYS,
      });
    } else if (!referrer && currentUrlParams.toString()) {
      setCustomCookie('origin', 'organic', {
        expires: COOKIE_EXPIRATION_DAYS,
      });
    }

    if (Object.keys(currentUtmParams).length > 0) {
      UTM_PARAMS.forEach(param => {
        Cookies.remove(param);
      });

      Object.keys(currentUtmParams).forEach(key => {
        setCustomCookie(key, currentUtmParams[key], {
          expires: COOKIE_EXPIRATION_DAYS,
        });
      });
    }

    const locationPathName =
      window.location.pathname === '/' ? '/en/' : window.location.pathname;

    if (!Cookies.get('first_page')) {
      setCustomCookie('first_page', window.location.origin + locationPathName, {
        expires: COOKIE_EXPIRATION_DAYS,
      });
    }

    setCustomCookie('last_page', window.location.origin + locationPathName, {
      expires: COOKIE_EXPIRATION_DAYS,
    });

    setCustomCookie('timestamp', Date.now(), {
      expires: COOKIE_EXPIRATION_DAYS,
    });
  }
};

const clearTrackingCookies = () => {
  [
    'origin',
    'first_page',
    'last_page',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_timestamp',
    'timestamp',
  ].forEach(cookieName => {
    Cookies.remove(cookieName, { path: '' });
  });
};

export const checkAndClearOldCookies = () => {
  const timestamp = Cookies.get('timestamp');
  if (timestamp) {
    const currentTime = Date.now();
    const timeElapsed = currentTime - timestamp;

    if (timeElapsed > COOKIE_EXPIRATION_MS) {
      clearTrackingCookies();
    }
  }
};

const getTrackingParams = () => {
  const origin = Cookies.get('origin');
  const firstPage = Cookies.get('first_page');
  let lastPage = Cookies.get('last_page');

  if (typeof window !== 'undefined' && isTrackingConsentTrue()) {
    const locationPathName =
      window.location.pathname === '/' ? '/en/' : window.location.pathname;

    lastPage = window.location.origin + locationPathName;
  }

  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_timestamp',
  ].reduce((params, key) => {
    const value = Cookies.get(key);
    if (value) {
      params[key] = value;
    }
    return params;
  }, {});

  return { origin, first_page: firstPage, last_page: lastPage, ...utmParams };
};

export const addTrackingParamsToUrl = (url, forceParams = false) => {
  if (!url) {
    return null;
  }

  if (!url?.includes('app.wooclap.com') && !forceParams) {
    return url;
  }

  const params = getTrackingParams();
  const filteredParams = Object.keys(params)
    .filter(key => params[key] !== undefined)
    .reduce((obj, key) => {
      // obj[key] = params[key].replace('http://localhost:8000', '');
      obj[key] = params[key].replace('https://www.wooclap.com', '');
      return obj;
    }, {});

  const queryString = new URLSearchParams(filteredParams).toString();
  const separator = queryString.length ? (url.includes('?') ? '&' : '?') : '';
  const uncodedUrl = decodeURIComponent(url);
  const uncodedQueryString = decodeURIComponent(queryString);

  return `${uncodedUrl}${separator}${uncodedQueryString}`;
};

export const isTrackingConsentTrue = () => {
  const cookieName = 'wc__cookie-consent';
  const cookieValue = getCookie(cookieName);
  if (cookieValue) {
    try {
      const consent = JSON.parse(decodeURIComponent(cookieValue));
      return consent.tracking === true;
    } catch (e) {
      Sentry.captureException(new Error(`Error while parsing cookie value`), {
        extra: {
          error: e,
        },
      });
      return false;
    }
  }
  return false;
};
