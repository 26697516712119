exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/Contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-event-tag-event-tag-js": () => import("./../../../src/templates/EventTag/EventTag.js" /* webpackChunkName: "component---src-templates-event-tag-event-tag-js" */),
  "component---src-templates-homepage-web-homepage-web-js": () => import("./../../../src/templates/HomepageWeb/HomepageWeb.js" /* webpackChunkName: "component---src-templates-homepage-web-homepage-web-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/Post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-pricing-pricing-js": () => import("./../../../src/templates/Pricing/Pricing.js" /* webpackChunkName: "component---src-templates-pricing-pricing-js" */)
}

